<template>
  <div>
    <Loading :loading="loading" />

    <section class="section">
      <div class="container">
        <h1 class="title has-text-centered">View and Download Tax Form</h1>
        <p class="subtitle has-text-centered" v-if="user.subType == 'expired'">
          Want your tax forms faster?
          <router-link :to="{ name: 'Signup' }">
            Request them with Tax ID Pro!
          </router-link>
        </p>
        <p class="subtitle has-text-centered" v-else>
          This form has automatically been added to
          <router-link :to="{ name: 'Settings' }">your account</router-link>.
        </p>
        <br />
      </div>

      <div class="container">
        <object v-if="url" :data="url" type="application/pdf" width="100%" height="700" />
      </div>
    </section>
  </div>
</template>

<script>
import { api } from '@/modules/util'

export default {
  name: 'Preview',
  props: ['user'],
  data() {
    return {
      loading: true,
      url: '',
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true
        const res = await api.get(`/forms/${this.$route.params.token}/preview`)
        this.url = res.data.url
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>